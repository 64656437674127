let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    meta: {
      breadCrumb: "Modifica Password",
      slug: "reset-password-request"
    },
    component: () => import("@/views/profile/ResetPasswordRequest.vue")
  },
  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: {
      slug: "reset-password-sent"
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    meta: {
      slug: "reset-password"
    }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    meta: {
      slug: "reset-password-completed"
    }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    meta: {
      slug: "registration-user"
    }
  },
  {
    path: "/registration-completed",
    name: "RegistrationCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: {
      slug: "registration-completed"
    }
  },
  {
    path: "/registration/confirm",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue")
    // meta: {
    //   slug: { slug: "registration-completed" }
    // }
  },
  {
    path: "/registration-confirmed",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    props: { showLogin: true },
    meta: {
      slug: "registration-confirmed"
    }
  },
  {
    path: "/anonymize-confirmed",
    name: "AnonymizeConfirmed",
    component: () => import("@/views/profile/AnonymizeConfirmed.vue"),
    props: { showLogin: true },
    meta: {
      slug: "anonymize-confirmed"
    }
  },
  {
    path: "/email-confirmed",
    name: "EmailConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    props: { showLogin: true },
    meta: {
      slug: "email-confirmed"
    }
  },
  {
    path: "/profile",
    meta: { requiresAuth: true },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "profile-update",
        name: "ProfileUpdate",
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          slug: "profile"
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          slug: "profile-error"
        },
        component: () => import("@/views/profile/ProfileUpdateError.vue")
      },
      {
        path: "update-password",
        name: "UpdatePassword",
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: "update-password"
        },
        component: () => import("@/views/profile/UpdatePassword.vue")
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        meta: { slug: "profile-confirmed" }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: "profile-updated" }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: "email-updated" }
      },
      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei ordini"
        },
        props: { ordersAllUsers: false },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "MyOrders",
            path: "",
            meta: {
              requiresAuth: true,
              slug: "orders"
            },
            component: () => import("@/views/profile/Orders.vue")
          },
          {
            name: "Order",
            path: ":orderId",
            props: { ordersAllUsers: false },
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              slug: "order"
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "all-orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "Tutti gli ordini"
        },
        props: true,
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "AllOrders",
            path: "",
            props: true,
            meta: {
              requiresAuth: true,
              slug: "all-orders"
            },
            component: () => import("@/views/profile/Orders.vue")
          },
          {
            name: "Order",
            path: ":orderId",
            props: { ordersAllUsers: true },
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              slug: "order"
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true,
              slug: "lists"
            },

            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId/grid",
            name: "ListDetailGrid",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/ListDetailGrid.vue")
          }
        ]
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I miei indirizzi" },

        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true,
              slug: "address"
            },
            component: () => import("@/views/profile/ProfileAddresses.vue")
          }
        ]
      },
      {
        path: "paymentduedate",
        name: "PaymentDueDate",
        meta: { requiresAuth: true, breadCrumb: "Le mie scadenze" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "PaymentDueDate",
            meta: {
              requiresAuth: true,
              slug: "duedate"
            },
            component: () => import("@/views/profile/PaymentDueDate.vue")
          }
        ]
      }
    ]
  }
];

export default routes;
