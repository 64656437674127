<template>
  <v-app-bar
    app
    color="white"
    :height="$vuetify.breakpoint.xsOnly ? 72 : 120"
    clipped-left
    :class="classCordova"
  >
    <v-col class="pa-0">
      <div class="primary">
        <div
          class="links  d-none d-sm-flex justify-sm-center justify-md-end align-center"
        >
          <div v-if="links.length > 0" @click="clicked">
            <a
              v-for="(link, index) in links"
              class="mx-3"
              v-bind:key="index"
              :href="link.url"
              >{{ link.title }}</a
            >
          </div>
        </div>
      </div>
      <!-- barra navigazione desktop -->
      <v-row class="nav mt-0">
        <v-col
          cols="1"
          sm="0"
          md="1"
          lg="1"
          xl="1"
          class="d-none d-sm-flex justify-center align-center"
        >
          <v-btn
            v-if="$routerHistory.hasPrevious() && $vuetify.breakpoint.xsOnly"
            elevation="0"
            @click.prevent.stop="historyBack"
            class="back-button"
            aria-label="Torna Indietro"
          >
            <v-icon medium elevation="0">mdi-chevron-left</v-icon>
          </v-btn>
          <!-- NO DRAWER MA MENU CON MASONRY -->
          <!-- <MasonryCategoryMenu></MasonryCategoryMenu> -->
          <v-btn
            class="drawer"
            @click.prevent.stop="drawerLeft = !drawerLeft"
            icon
            large
            color="primary"
          >
            <div class="d-flex flex-column justify-center align-center">
              <v-icon medium>$menu</v-icon>
              <div class="label">{{ $t("navbar.products") }}</div>
            </div>
          </v-btn>
          <!-- <v-app-bar-nav-icon
            @click.prevent.stop="drawerLeft = !drawerLeft"
            class="drawer-right drawer"
          >
            <div class="d-flex flex-column justify-center align-center">
              <v-icon medium>$menu</v-icon>
              <div class="label">{{ $t("navbar.products") }}</div>
            </div>
          </v-app-bar-nav-icon> -->
        </v-col>
        <v-col
          cols="6"
          sm="3"
          md="3"
          lg="3"
          xl="3"
          class="d-flex justify-start justify-md-center align-center p-sm-auto"
        >
          <router-link to="/" class="px-0">
            <!-- TODO: riferimenti a GrosMarket -->
            <img src="/logo.svg" alt="Logo GrosMarket" class="header-logo" />
          </router-link>
        </v-col>
        <!-- CAMPO DI RICERCA (non visibile su phone, spostato nella BottomNavBar) -->
        <v-col
          cols="5"
          sm="4"
          lg="3"
          class="search pa-0 d-none d-sm-flex flex-row align-center"
        >
          <SearchInput v-if="!$vuetify.breakpoint.xsOnly" />
        </v-col>
        <v-col cols="6" sm="4" lg="5" class="d-flex justify-end pr-1">
          <!-- NON AUTENTICATO -->
          <div class="not-auth profile-nav justify-end" v-if="!isAuthenticated">
            <v-btn
              class="fast-search d-none d-sm-flex"
              medium
              elevation="0"
              text
              height="48"
              @click="openFastSearchDialog"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-icon>$fastSearch</v-icon>
                <div class="label">
                  <span>
                    SPESA VELOCE
                  </span>
                </div>
              </div>
            </v-btn>
            <v-btn
              @click.stop="handleLogin()"
              medium
              elevation="0"
              height="48"
              text
              class="mx-1 mx-md-1"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-icon medium>$profile</v-icon>
                <div class="label d-none d-sm-flex">&nbsp;</div>
              </div>
            </v-btn>
            <v-btn
              medium
              elevation="0"
              height="48"
              text
              @click.stop="handleLogin()"
              class="mx-1 mx-md-1"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-icon medium>$cart</v-icon>
                <div class="label d-none d-sm-flex">&nbsp;</div>
              </div>
            </v-btn>
          </div>

          <!-- AUTENTICATO -->
          <div v-else class="cart-navbar profile-nav justify-end">
            <!-- fast search spesa veloce -->
            <v-btn
              class="fast-search d-none d-sm-flex"
              medium
              elevation="0"
              text
              height="48"
              @click="openFastSearchDialog"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-icon>$fastSearch</v-icon>
                <div class="label">
                  <span>
                    SPESA VELOCE
                  </span>
                </div>
              </div>
            </v-btn>
            <!-- CONSEGNA -->
            <v-btn
              class="d-none d-lg-flex"
              @click="openTimeSlotSelector()"
              medium
              elevation="0"
              text
              height="48"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-icon medium>$consegna</v-icon>
                <div class="label">
                  <span v-if="!$vuetify.breakpoint.xs">
                    {{ $t("navbar.delivery") }}
                  </span>
                </div>
              </div>
            </v-btn>

            <!-- LISTE (visibile in BottomNavBar) -->
            <v-btn
              aria-label="Vai alle liste dei preferiti"
              class="d-none d-lg-flex profile-nav"
              to="/profile/lists"
              medium
              elevation="0"
              height="48"
              text
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-icon medium>$heart</v-icon>
                <div class="label">{{ $t("navbar.lists") }}</div>
              </div>
            </v-btn>
            <!-- PROFILO -->
            <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on.stop="on"
                  medium
                  elevation="0"
                  text
                  height="48"
                >
                  <div
                    class="d-flex flex-column align-center"
                    style="max-width: 60px;"
                  >
                    <v-icon color="primary" medium>$profilefull</v-icon>
                    <div class="label profile-name">
                      <span v-if="!$vuetify.breakpoint.xs">
                        {{ cart.user.person.company }}
                      </span>
                    </div>
                  </div>
                </v-btn>
              </template>
              <MenuNavigator class="account-menu" />
            </v-menu>

            <!-- CARRELLO -->
            <v-badge
              :content="totalItems"
              :value="totalItems > 0"
              :key="totalItems"
              transition="bounce"
              color="primary"
              overlap
            >
              <v-btn
                medium
                elevation="0"
                text
                height="48"
                @click.stop="openCart()"
                class="ml-md-3"
              >
                <div class="d-flex flex-column justify-center align-center">
                  <v-icon medium>$cart</v-icon>
                  <div class="label">{{ $n(cart.totalPrice, "currency") }}</div>
                </div>
              </v-btn>
            </v-badge>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="accent"
    ></v-progress-linear>
  </v-app-bar>
</template>

<style scoped lang="scss">
.header-logo {
  max-width: 100%;
}
.v-app-bar {
  height: 120px !important;
  .col {
    height: 100%;
  }
  .links {
    height: $navbar-links-container-height;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: #{map-get($container-max-widths, "sm")};
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      max-width: #{map-get($container-max-widths, "md")};
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: #{map-get($container-max-widths, "lg")};
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
  .cart-navbar,
  .not-auth {
    width: 100%;
  }
  .profile-nav {
    .fast-search {
      margin-left: 0px;
      margin-right: auto;
      .label,
      .v-icon {
        color: var(--v-secondary-base);
      }
    }
    .v-btn {
      color: var(--v-grey-lighten1);
    }
  }
  .nav {
    height: 100%;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 80px;
      max-width: #{map-get($container-max-widths, "sm")};
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      max-width: #{map-get($container-max-widths, "md")};
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: #{map-get($container-max-widths, "lg")};
    }

    .drawer.v-btn {
      background: $secondary;
      min-width: 100px !important;
      width: 100px;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        min-width: 75px !important;
        width: 75px;
      }
      height: 80px;
      margin-top: -12px;
      margin-bottom: -12px;
      border-radius: 0;
      i {
        color: $white !important;
        font-size: 30px;
      }
      .label {
        color: $white;
        min-height: 7px;
        line-height: 15px;
        font-size: 10px;
      }
    }
    .search {
      background: var(--v-grey-lighten2);
    }

    .v-btn {
      .label {
        line-height: 14px;
        font-size: 9px;
      }
    }

    .profile-name {
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media #{map-get($display-breakpoints, 'sm-only')} {
        width: 60px;
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        width: 40px;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 69px !important;
    .v-btn {
      .label {
        font-size: 11px !important;
        font-weight: bold;
      }
    }
  }
}
</style>

<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import FastSearchMenu from "@/components/navigation/FastSearchMenu.vue";
import MenuNavigator from "@/components/profile/MenuNavigator.vue";

import login from "~/mixins/login";
import clickHandler from "~/mixins/clickHandler";
import cartInfo from "~/mixins/cartInfo";

// import { forEachCartItem } from "~/service/ebsn";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Navbar",
  props: ["links", "user"],
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput,
    MenuNavigator
    // MasonryCategoryMenu
  },
  data() {
    return {
      isMobile: false
    };
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    goBack() {
      this.$router.go(-1);
    },

    async openTimeSlotSelector() {
      await this.setTimeslot();
    },
    openFastSearchDialog() {
      var defaultConfig = {
        toolbarColor: "secondary"
      };
      let properties = {
        waitForResult: true,
        width: 750
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({ showOverlay: "app/showOverlay" }),
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  }
  // beforeDestroy() {
  //   if (typeof window === "undefined") return;
  //   window.removeEventListener("resize", this.onResize, { passive: true });
  // },
  // async mounted() {
  //   var _this = this;
  //   setTimeout(function() {
  //     _this.onResize();
  //     window.addEventListener("resize", _this.onResize, { passive: true });
  //   }, 500);
  //   setTimeout(function() {
  //     _this.onResize();
  //   }, 3000);
  // }
};
</script>
