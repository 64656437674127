<template>
  <v-app
    :class="[
      getKeyboardOpen ? classes + ' keyboard-open' : classes,
      { 'platform-ios': platformIos }
    ]"
  >
    <!-- drawer sinistro di navigazione categorie -->
    <Navbar v-if="showApp" :key="key" :links="navBarLinks" :user="user" />
    <v-navigation-drawer
      class="drawer-left secondary"
      v-if="showApp"
      v-model="drawerLeft"
      :temporary="true"
      touchless
      floating
      app
      top
    >
      <!-- touchless
      left
      app
      clipped -->
      <CategoryTree
        @close="drawerLeft = false"
        v-if="$vuetify.breakpoint.smAndDown"
      />
      <MasonryCategoryMenu @close="drawerLeft = false" v-else />
    </v-navigation-drawer>

    <!-- drawer destro di carrello -->
    <v-navigation-drawer
      v-if="showApp"
      v-model="drawerRight"
      fixed
      temporary
      right
      app
      width="600"
      class="cart-drawer"
    >
      <div class="cart-info">
        <CartInfoList :isCart="true" />
      </div>

      <CartItemList />
      <CartSummary />
    </v-navigation-drawer>

    <!-- 
      drawer supplementare destro per visualizzazione link,
      attivabile soltanto in modalità smartphone e con il button
      della BottomNavBar
    -->
    <v-navigation-drawer
      class="drawer-links"
      v-if="showApp"
      :stateless="!isMobile"
      v-model="drawerLinks"
      touchless
      right
      app
    >
      <MenuLinksList :links="navBarLinks" @close="drawerLinks = false" />
    </v-navigation-drawer>

    <!-- 
      drawer di ricerca inferiore,
      attivabile soltanto in modalità smartphone e con il button
      Cerca della BottomNavBar
    -->
    <!-- <v-navigation-drawer
      class="drawer-search secondary"
      v-if="showApp"
      :stateless="!isMobile"
      v-model="drawerSearch"
      touchless
      bottom
      app
    >
      <SearchPanel @close="drawerSearch = false" />
    </v-navigation-drawer> -->

    <v-main>
      <!-- <Tutorial v-if="$platform_is_cordova" /> -->
      <div class="content-wrap">
        <v-skeleton-loader
          type="actions, image, article,image"
          v-if="loading"
          :loading="loading"
        ></v-skeleton-loader>
        <router-view v-else></router-view>
      </div>
      <v-footer v-if="showApp" class="pb-0">
        <!-- <v-btn @click="onResume">reload</v-btn> -->
        <Footer :links="footerLinks" />
      </v-footer>
    </v-main>

    <v-fab-transition v-if="showApp">
      <!-- <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        right
        color="primary"
        class="toTop"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn> -->
    </v-fab-transition>

    <!-- PULSANTI "SCOPRI SERVIZI" E "DIVENTA CLIENTE" (non autenticato) -->
    <InviteButtons v-if="!isAuthenticated && isHome" />
    <BottomNavBar class="d-flex d-sm-none" />
  </v-app>
</template>
<script>
import CartSummary from "@/components/cart/CartSummary";
import CartInfoList from "@/components/cart/CartInfoList";
import CartItemList from "@/components/cart/CartItemList";
import CategoryTree from "@/components/navigation/CategoryTree";
import MasonryCategoryMenu from "@/components/navigation/MasonryCategoryMenu";
import MenuLinksList from "@/components/navigation/MenuLinksList";
import Footer from "@/components/navigation/Footer";
import Navbar from "@/components/navigation/Navbar";
// import SearchPanel from "@/components/navigation/SearchPanel";
import BottomNavBar from "@/components/navigation/BottomNavBar";
import InviteButtons from "@/components/navigation/InviteButtons";
import CmService from "~/service/cmService";
import login from "~/mixins/login";
import version from "~/mixins/version";
import { mapActions, mapGetters } from "vuex";
import pushNotification from "~/mixins/pushNotification";
// import SecurityService from "~/service/securityService";
import findIndex from "lodash/findIndex";
import analyticsService from "./commons/service/analyticsService";

export default {
  name: "App",
  components: {
    CategoryTree,
    CartInfoList,
    CartSummary,
    CartItemList,
    MasonryCategoryMenu,
    MenuLinksList,
    Footer,
    Navbar,
    // SearchPanel,
    InviteButtons,
    BottomNavBar
  },
  mixins: [login, version, pushNotification],
  data() {
    return {
      fab: false,
      load404: false,
      initialHref: window.location.href,
      loading: true,
      key: 1,
      navBarLinks: [],
      footerLinks: [],
      keyboardOpen: false,
      classes: process.env.CORDOVA_PLATFORM ? "is-cordova" : "",
      platformIos: false,
      attValue: false,
      attResponse: false
    };
  },
  metaInfo: {
    //TODO: riferimenti GrosMarket
    title: "GrosMarket",
    titleTemplate: "%s | GrosMarket GrosMarket Cash&Carry",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content: "GrosMarket Cash&Carry, Food&Service a tua misura"
      },
      {
        name: "keywords",
        content: "GrosMarket Cash&Carry "
      },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "author", content: "Digitelematica s.r.l." }
    ]
  },
  computed: {
    ...mapGetters({
      needRestart: "needRestart",
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isHome() {
      return this.$route.name === "Home";
    },
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    drawerRight: {
      get() {
        return this.$store.state.app.drawerRight;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerRight", value);
      }
    },
    drawerLinks: {
      get() {
        return this.$store.state.custom.drawerLinks;
      },
      set(value) {
        this.$store.commit("custom/updatedDrawerLinks", value);
      }
    },
    drawerSearch: {
      get() {
        return this.$store.state.custom.drawerSearch;
      },
      set(value) {
        this.$store.commit("custom/updatedDrawerSearch", value);
      }
    },
    showApp: function() {
      return !this.load404;
    },
    getKeyboardOpen: function() {
      return this.keyboardOpen;
    }
  },
  methods: {
    ...mapActions({
      updateAuth: "cart/updateAuth",
      resetFilters: "category/resetFilters",
      updateLastStartTime: "app/updateLastStartTime"
    }),
    fetchNavBarLinksMenu() {
      CmService.getMenuByLocation("primary").then(
        data => (this.navBarLinks = data)
      );
    },
    fetchFooterLinksMenu() {
      CmService.getMenuByLocation("footer").then(
        data => (this.footerLinks = data)
      );
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    isPlatformIos() {
      if (this.isCordova && window.cordova.platformId.toLowerCase() === "ios") {
        this.platformIos = true;
      }
    },
    onAppTrackingTransparency() {
      var _this = this;
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      if (
        _this.$platform_is_cordova &&
        platform == "ios" &&
        window.plugins.impacTracking
      ) {
        console.log("Calling onAppTrackingTransparency");
        window.plugins.impacTracking.trackingAvailable(
          function(available) {
            if (!available) {
              console.log(
                "Tracciamento non disponibile o disabilitato dall'utente"
              );
              window.plugins.impacTracking.canRequestTracking(
                function(result) {
                  // Show results in the console
                  console.log(result);
                  if (result == true) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                    window.plugins.impacTracking.requestTracking(
                      undefined,
                      function(res) {
                        console.log("AppTracking enabled: " + res);
                        _this.attValue = res;
                        if (res == true) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.submitCustomConsent(true, true, true);
                        }
                        if (res == false) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.hide();
                        }
                      },
                      function(err) {
                        console.log(err);
                      }
                    );
                  } else {
                    console.log(
                      "Tracciamento non disponibile o disabilitato dall'utente"
                    );
                  }
                },
                function(err) {
                  console.log(err);
                  console.log(
                    "Tracciamento non disponibile o disabilitato dall'utente"
                  );
                }
              );
            } else {
              window.plugins.impacTracking.requestTracking(
                undefined,
                function(res) {
                  console.log(
                    "Calling onAppTrackingTransparency; already defined"
                  );
                  console.log("AppTracking enabled: " + res);
                  _this.attValue = res;
                  // eslint-disable-next-line no-undef
                  var stats = Cookiebot.consent.statistics;
                  // eslint-disable-next-line no-undef
                  var mrkg = Cookiebot.consent.marketing;
                  // eslint-disable-next-line no-undef
                  var pref = Cookiebot.consent.preferences;
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  if (res == false) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                  }
                },
                function(err) {
                  console.log(err);
                }
              );
            }
          },
          function(err) {
            console.log(err);
          }
        );
      }
    },
    async onResume() {
      var _this = this;
      var hash;
      setTimeout(async function() {
        hash = findIndex(global.config.hashNotReload, function(o) {
          return window.location.hash.indexOf(o) !== -1;
        });
        var needRestart =
          new Date().getTime() - _this.$store.state.app.lastStartTime >
          global.config.reloadAfterMillis;
        if (hash === -1 && needRestart) {
          global.EventBus.$emit("restart");
        } else {
          if (hash === -1 && _this.$cookies.get("X-Ebsn-Session")) {
            await _this.loadCart();
          }
          global.EventBus.$emit("reload");
        }
      }, 100);
    },
    async handleLogin() {
      this.doLogin("response419");
    },
    async onDeepLinks(path, queryString) {
      var _this = this;
      var currentQuery = {};
      console.log("onDeepLinks :::: ", path);
      console.log("onDeepLinks queryString :::: ", queryString);
      console.log("_this.$router :::: ", _this.$router);
      setTimeout(async function() {
        if (queryString) {
          currentQuery.q = queryString.replace("q=", "").replace(/%20/g, " ");
          console.log("onDeepLinks currentQuery :::: ", currentQuery);
          _this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        } else {
          _this.$router.push(path);
        }
      }, 100);
    },
    async refreshLogin() {
      if (global.config.pushNotificationEnabled) {
        // console.log("REFRESH LOGIN - REGISTER FIREBASE DEVICE");
        // Al Deviceready facciamo un refresh login per controllare se l'utente è ancora collegato
        // await SecurityService.refreshLogin();
        // await this.registerFirebaseDevice();
      }
    },
    ...mapActions({
      loadCart: "cart/loadCart"
    })
  },
  async created() {
    // if (this.$platform_is_cordova) {
    // // eslint-disable-next-line no-undef
    // Cookiebot.hide();
    // window.addEventListener("CookiebotOnDialogInit", function() {
    //   // eslint-disable-next-line no-undef
    //   Cookiebot.hide();
    // });
    // window.addEventListener("CookiebotOnDialogDisplay", function() {
    //   // eslint-disable-next-line no-undef
    //   Cookiebot.hide();
    // });
    // }

    this.fetchNavBarLinksMenu();
    this.fetchFooterLinksMenu();
    var _this = this;
    _this.load404 = false;
    try {
      if (_this.$cookies.get("X-Ebsn-Session")) {
        await _this.loadCart();
      } else if (window.localStorage.getItem("X-Ebsn-Session")) {
        let session = window.localStorage.getItem("X-Ebsn-Session");
        _this.$cookies.set("X-Ebsn-Session", session);

        let account = window.localStorage.getItem("X-Ebsn-Account");
        _this.$cookies.set("X-Ebsn-Account", account);

        await _this.loadCart();
      } else {
        analyticsService.initCustomDimension();
      }
    } finally {
      _this.loading = false;
    }

    global.EventBus.$on("isNotLoggedIn", () => {
      _this.updateAuth();
      _this.handleLogin();
    });
    global.EventBus.$on("restart", () => {
      _this.updateLastStartTime();
      window.location.reload();
    });
    global.EventBus.$on("resetFilters", () => {
      _this.resetFilters(Math.random());
    });
    setTimeout(function() {
      _this.key = _this.key + 1;
    }, 3000);
    //eventi legati a cordova, partono solo al deviceready che viene scatenato da cordova quanto la app è pronta
    document.addEventListener(
      "deviceready",
      () => {
        console.log("deviceready ::::: ", window.innerWidth);
        setTimeout(function() {
          if (window.innerWidth < 600) {
            screen.orientation.lock("portrait");
          }
        }, 1500);

        //save last start time
        _this.updateLastStartTime();

        document.addEventListener("resume", _this.onResume, false);

        // TODO: Gestire la lista dei Routes da controllare su config.
        window.IonicDeeplink.route(
          global.config.deeplinks,
          function(match) {
            console.log("MATCH :::: ", match);
            _this.onDeepLinks(match.$link.path, match.$link.queryString);
          },
          function(nomatch) {
            console.log("NOMATCH :::: ", nomatch);
          }
        );

        this.onAppTrackingTransparency();

        //con x secondi controllo se c'è una versione più recente
        _this.checkVersion();

        _this.isPlatformIos();

        // Plugin Keyboard
        window.addEventListener("keyboardDidHide", () => {
          // Describe your logic which will be run each time keyboard is closed.
          console.log("keyboardDidHide");
          _this.keyboardOpen = false;
        });
        window.addEventListener("keyboardDidShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardDidShow");
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardWillShow");
        });
        window.addEventListener("keyboardWillHide", () => {
          // Describe your logic which will be run each time when keyboard is about to be closed.
          console.log("keyboardWillHide");
        });
        _this.refreshLogin();

        window.addEventListener(
          "CookiebotOnAccept",
          function() {
            console.log("CookiebotOnAccept");
            _this.attValue = true;
          },
          false
        );

        window.addEventListener(
          "CookiebotOnDecline",
          function() {
            console.log("CookiebotOnDecline");
            _this.attValue = false;
          },
          false
        );

        window.addEventListener(
          "CookiebotOnDialogDisplay",
          function() {
            console.log("Calling CookiebotOnDialogDisplay");
            // eslint-disable-next-line no-undef
            var hasResponded = Cookiebot.consented || Cookiebot.declined;
            if (
              window.cordova &&
              window.cordova.platformId.toLowerCase() == "ios" &&
              window.plugins.impacTracking &&
              !_this.attResponse
            ) {
              if (_this.attValue) {
                console.log("Ho cliccato su consenti");
                // Ho cliccato su "Consenti"
                // eslint-disable-next-line no-undef
                var stats = Cookiebot.consent.statistics;
                // eslint-disable-next-line no-undef
                var mrkg = Cookiebot.consent.marketing;
                // eslint-disable-next-line no-undef
                var pref = Cookiebot.consent.preferences;
                if (!hasResponded) {
                  console.log("Not responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(false, false, false);
                  _this.attResponse = true;
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                } else {
                  console.log("Has responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  _this.attResponse = true;
                }
              } else {
                console.log("Ho cliccato su non consentire");
                //Ho cliccato su "non consentire"
                // eslint-disable-next-line no-undef
                var declined = Cookiebot.declined;
                // eslint-disable-next-line no-undef
                Cookiebot.withdraw();
                _this.attResponse = true;
                if (!declined) {
                  console.log("First time");
                  //first time
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                }
              }
            }
          },
          false
        );
      },
      false
    );
  },
  mounted() {
    // Avoid loading Cookiebot if the page is server rendered (Prerender.io)
    // DOC: Prerender.io crawler User-Agent will always contain: "Prerender (+https://github.com/prerender/prerender)" - src:   https://docs.prerender.io/docs/33-overview-of-prerender-crawlers
    // if (!navigator.userAgent.match("Prerender|GTmetrix|Googlebot")) {
    //   let cookiebotScript = document.createElement("script");
    //   cookiebotScript.setAttribute("id", "Cookiebot");
    //   cookiebotScript.setAttribute(
    //     "src",
    //     "https://consent.cookiebot.com/uc.js"
    //   );
    //   cookiebotScript.setAttribute(
    //     "data-cbid",
    //     "bc2bd6ca-dc3c-494d-b7c0-a7f1a1c44cb9"
    //   );
    //   cookiebotScript.setAttribute("type", "text/javascript");
    //   cookiebotScript.async = true;
    //   document.head.appendChild(cookiebotScript);
    // }
  },
  watch: {
    $route(to) {
      this.load404 = false;
      if (to.meta.load404) {
        this.load404 = to.meta.load404;
      }
    }
  }
};
</script>
