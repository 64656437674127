import base from "./base";
import wordpress from "./wordpress";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";
import store from "./store";

const routes = [
  {
    path: "/",
    name: "Home",
    props: true,
    meta: { slug: "home-new" },
    component: () => import("@/views/Home.vue")
  },

  ...base,
  ...wordpress,
  ...catalog,
  ...profile,
  ...checkout,
  ...store,

  {
    path: "*",
    name: "DefaultRoute",
    component: () => import("@/views/Home.vue"),
    meta: { slug: "home-new" }
  }
];

export default routes;
