<template>
  <div ref="menu" class="masonry-category-menu">
    <div class="d-flex masonry-row">
      <div class="all-products" flat>
        <v-list flat dense :height="listHeight">
          <v-list-item-group v-model="selectedCategoryFirstLevel">
            <v-list-item
              v-for="category in categories"
              :key="category.categoryId"
              @click.native.prevent="selectCategory(category)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <router-link
                    :to="{
                      name: 'Category',
                      params: { pathMatch: category.slug }
                    }"
                    event
                    @click.native.prevent="selectCategory(category)"
                  >
                    {{ category.name }}
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="category2" flat :cols="undefined" v-if="categorySelected">
        <div
          class="masonry-layout masonry-layout-col-3 masonry-layout-md-col-2"
        >
          <v-list
            dense
            v-for="(category2, index) in categorySelected.categories"
            :key="index"
            class="masonry-list masonry-layout__panel"
          >
            <v-list-item-title class="subheaders">
              <router-link
                :to="{
                  name: 'Category',
                  params: { pathMatch: category2.slug }
                }"
                link
                :key="category2.categoryId"
                @click.native.prevent="close"
                >{{ category2.name }}
              </router-link>
            </v-list-item-title>
            <v-list-item-group color="primary">
              <v-list-item
                active-class="link-active"
                v-for="category3 in category2.categories"
                :key="category3.categoryId"
                link
                :to="{
                  name: 'Category',
                  params: { pathMatch: category3.slug }
                }"
                @click="close"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="category3.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.v-navigation-drawer .v-list:not(.v-select-list) {
  background: transparent;
}
.masonry-category-menu {
  background-color: $white;
  height: 100%;
}
.masonry-layout {
  column-gap: 0;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
}
.category2 {
  background-image: url("/img/left-drawer-background.png");
  background-position: right bottom; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
}
.masonry-layout-col-2 {
  column-count: 2;
  -webkit-column-count: 2;
  -moz-column-count: 2;
}

.masonry-layout-col-3 {
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3;
}

.masonry-layout__panel {
  break-inside: avoid;
}

.masonry-layout__panel-content {
  padding: 10px;
  font-size: 13px;
  line-height: 18px;
}

.v-menu__content {
  max-width: 90% !important;
  width: 90%;
}
.menu {
  background: $white !important;
  height: 100%;
}
.v-btn {
  background: $secondary !important;
  min-width: 100px !important;
  width: 100px;
  height: 80px !important;
  margin-top: -12px;
  margin-bottom: -12px;
  border-radius: 0;
  i {
    color: $white !important;
    font-size: 30px;
  }
  .label {
    color: $white;
    min-height: 7px;
    line-height: 15px;
    font-size: 10px;
    width: 100%;
  }
}
.masonry-row {
  width: 100%;
  flex-wrap: nowrap;
  height: 100%;
  .all-products,
  .category2 {
    overflow-y: auto;
  }
}
.all-products {
  padding: 0px;
  background-color: var(--v-secondary-base);
  font-size: 16px;
  color: $white;
  width: 250px;
  max-width: 250px;
  .subheaders {
    color: rgba(255, 255, 255, 0.57) !important;
    font-size: 16px;
    padding: 0 16px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .v-list {
    // height: 100%;
    overflow-y: auto;
  }
  .v-list-item__content {
    padding: 8px 0;
  }
  // .v-list-item {
  //   border-bottom: 1px solid;
  //   // border-color: $masonry-background-active;
  // }
  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(255, 255, 255, 0.57) !important;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3) !important;
      color: $white !important;
    }
  }
  .v-item--active {
    color: $white;
    font-weight: 700;
    .v-list-item__title {
      font-weight: bold;
    }
  }
  .v-list-item__title {
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 15px;
  }
}
.category2 > div {
  div {
    border-left: 1px solid#e5e5e5;
  }
  .v-list-item__title,
  .v-list-item__content,
  .v-item-group {
    border-color: transparent;
    border: 0px none;
  }
}
.category2 {
  background-color: $white; /*Default bg, similar to the background's base color*/
  background-position: right bottom; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  font-size: 12px;
  color: $black;
  width: 100%;
  .subheaders {
    color: $text-color !important;
    font-size: 13px;
    padding: 0 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .masonry-list {
    border-left: 1px solid var(--v-grey-lighten2);
  }
  .v-list-item__content {
    color: $text-color !important;
    padding: 5px 0;
  }
  .v-list--dense .v-list-item {
    min-height: 20px;
  }
  .link-active {
    color: $secondary;
  }
  .link-active:before {
    background: $secondary;
    opacity: 0.2;
  }
}
</style>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "MasonryCategoryMenu",
  data() {
    return {
      menu: false,
      selectedCategoryFirstLevel: 0,
      categorySelected: null,
      // DA TESTARE CON I VARI BREAKPOINTS FINO A TABLET "MD".
      masonryCols: { default: 4, 1744: 4, 1264: 3, 960: 2, 400: 1 },
      masonryGutters: {
        default: "3px"
      },
      allCategories: [],
      listHeight: "100%"
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    getCategoriesBySlug() {
      var _this = this;
      var categoryObject = {};
      _this.allCategories = [];
      if (this.categorySelected) {
        this.categories.forEach(element => {
          if (element.categoryId === _this.categorySelected.categoryId) {
            element.categories.forEach(category => {
              categoryObject = category;
              _this.allCategories.push(categoryObject);
            });
          }
        });
      }
      return _this.allCategories;
    }
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    onResize() {
      this.listHeight = document.getElementsByClassName(
        "drawer-left"
      )[0].offsetHeight;
    },
    selectCategory(cat) {
      if (cat) {
        this.categorySelected = cat;
      } else {
        this.categorySelected = { categories: this.categories };
      }
    },
    close() {
      this.menu = false;
    }
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    this.reload();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    categories() {
      if (this.categories && this.categories.length) {
        this.selectCategory(this.categories[0]);
      }
    }
  }
};
</script>
