<template>
  <v-list class="pb-0">
    <v-list-item to="/profile/profile-update">
      <v-list-item-title>{{
        $t("navbar.navigation.profile")
      }}</v-list-item-title>
    </v-list-item>
    <v-list-item to="/profile/orders">
      <v-list-item-title>{{
        $t("navbar.navigation.orders")
      }}</v-list-item-title>
    </v-list-item>
    <!-- <v-list-item to="/profile/orders-all-users"> -->
    <v-list-item
      v-if="isMasterAccount"
      :to="{
        name: 'AllOrders',
        params: { ordersAllUsers: true }
      }"
    >
      <v-list-item-title>{{
        $t("navbar.navigation.allOrders")
      }}</v-list-item-title>
    </v-list-item>
    <v-list-item to="/profile/addresses">
      <v-list-item-title>{{
        $t("navbar.navigation.addresses")
      }}</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="isMasterAccount && isSsoUser"
      to="/profile/paymentduedate"
      class="d-flex"
    >
      <v-list-item-title>{{
        $t("navbar.navigation.paymentduedate")
      }}</v-list-item-title>
    </v-list-item>
    <!-- <v-list-item to="/profile/coupons">
              <v-list-item-title>Promozioni esclusive</v-list-item-title>
            </v-list-item> -->
    <v-list-item to="/profile/lists" class="d-flex">
      <v-list-item-title>{{ $t("navbar.navigation.lists") }}</v-list-item-title>
    </v-list-item>
    <v-list-item
      @click.stop="logout"
      class="d-flex font-weight-bold text-uppercase"
    >
      <v-list-item-title>{{
        $t("navbar.navigation.logout")
      }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<style scoped lang="scss"></style>

<script>
import { mapActions, mapGetters } from "vuex";
import pushNotification from "~/mixins/pushNotification";

export default {
  name: "MenuNavigator",
  mixins: [pushNotification],
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    })
  },
  computed: {
    ...mapGetters({
      isMasterAccount: "cart/isMasterAccount",
      isSsoUser: "cart/isSsoUser"
    })
  }
};
</script>
