import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: { base: "#FF6000", lighten1: "#fa8034", lighten2: "#FFE7D9" },
        secondary: {
          base: "#18988B",
          darken1: "#14867B",
          lighten1: "#d1eae8",
          lighten2: "#DEEAE8"
        },
        grey: {
          base: "#c4c4c4",
          darken1: "#636363",
          lighten1: "#868686",
          lighten2: "E5E5E5",
          lighten3: "#F8F8F8"
        },
        accent: "#DEECE9"
      }
    }
  },
  breakpoint: {
    thresholds: {
      lg: 1585
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      search: "icon-search",
      heart: "icon-heart",
      heartFull: "icon-heartfull",
      menu: "icon-burgermenu",
      profile: "icon-profile",
      cart: "icon-cart",
      minus: "icon-minus",
      plus: "icon-plus",
      edit: "icon-edit",
      fastSearch: "icon-fast-search",
      print: "icon-print",
      delete: "icon-delete",
      consegna: "icon-consegna",
      promo: "icon-promo",
      rightmenu: "icon-dots",
      profilefull: "icon-profilefull",
      star: "icon-star",
      starfull: "icon-starfull",
      reorder: "icon-reorder",
      invoice: "icon-invoice",
      menuMobile: "icon-menu-mobile",
      iconUsers: "icon-users"
    }
  }
});
